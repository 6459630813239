import { useMotionValueEvent, useScroll } from "framer-motion"
import { createContext, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";
import useFloodlightTracking from "./useFloodlightTracking";
import useGATracking from "./useGATracking";
import useFBTracking from "./useFBTracking";


interface TrackingContextValues {
    trackSubmit: (id:string, filiale?:string) => void;
    trackCTA: (id:string) => void;
    trackView: (id:string) => void;
    trackStartChat: (id:string, chatName:string) => void;
    trackEndChat: (id:string) => void;
    trackChatCTA: (id:string) => void;
    screenView: (screenName:string) => void;
}

const TrackingContext = createContext<TrackingContextValues>({
    trackSubmit: (id, filiale) => {},
    trackCTA: (id) => {},
    trackView: (id) => {},
    trackStartChat: (id, chatName) => {},
    trackEndChat: (id) => {},
    trackChatCTA: (id) => {},
    screenView: (screenName) => {},
})

type TrackingProps = {
    children: ReactNode;
    scrollTrackContainerRef?: RefObject<HTMLElement>
}

type ChatDetails = {
    chatName: string
    chatStartDate: Date
}

const Tracking = ({ children, scrollTrackContainerRef }: TrackingProps): JSX.Element => {

    const isOnline: boolean = process.env.NEXT_PUBLIC_IS_ONLINE === "true";

    const {fl_trackSubmit, fl_trackCTA, fl_trackDwell20Seconds, fl_trackScroll50Percent, fl_pageView} = useFloodlightTracking(isOnline)
    const {ga_trackSubmit, ga_trackCTA, ga_trackDwell20Seconds, ga_trackScroll50Percent, ga_pageView, ga_trackView, ga_screenView} = useGATracking(isOnline)
    const {fb_trackSubmit, fb_trackCTA, fb_trackDwell20Seconds, fb_trackScroll50Percent, fb_pageView} = useFBTracking(isOnline)

    const [chatDetails, setChatDetails] = useState<ChatDetails | null>(null)
    const [currentScreenName, setCurrentScreenName] = useState<string>('')

    /* scroll progress related events, scroll container can be specified by scroll reference */
    const [scrollDepthReached50Percent, setScrollDepthReached50Percent] = useState(false)
    const { scrollYProgress } = useScroll(scrollTrackContainerRef ? {container: scrollTrackContainerRef} : {})
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        if(latest > 0.5) setScrollDepthReached50Percent(true)
    })
    useEffect(() => {
        try {
            if(scrollDepthReached50Percent) {
                console.debug('Tracking: scroll depth 50% reached')
                fl_trackScroll50Percent()
                ga_trackScroll50Percent()
                fb_trackScroll50Percent()
            }
        } catch (e) {
            console.error(e)
        }
    }, [scrollDepthReached50Percent])

    /* events that can be used within the context */
    const trackSubmit = useCallback((id:string, filiale?:string) => {
        try {
            fl_trackSubmit()
            if(filiale) {
                ga_trackSubmit(id, {filiale: filiale});
            } else {
                ga_trackSubmit(id, {});
            }
            fb_trackSubmit(id + '' + filiale)
        } catch (e) {
            console.error(e)
        }
    }, []);

    const trackCTA = useCallback((id:string) => {
        try {
            fl_trackCTA()
            ga_trackCTA(id)
            fb_trackCTA(id)
        } catch (e) {
            console.error(e)
        }
    }, []);

    const trackView = useCallback((id:string) => {
        try {
            ga_trackView(id)
        } catch (e) {
            console.error(e)
        }
    }, []);

    const trackStartChat = useCallback((id:string, chatName:string) => {
        try {
            ga_trackView(id + '_' + chatName)
            setChatDetails({
                chatName: chatName,
                chatStartDate: new Date()
            })
        } catch (e) {
            console.error(e)
        }
    }, []);

    const trackEndChat = useCallback((id:string) => {
        try {
            if(chatDetails?.chatStartDate){
                const chatDuration = Math.floor((new Date().getTime() - chatDetails?.chatStartDate.getTime()) / 1000) 
                id += '_' + chatDetails.chatName + '_' + chatDuration
            }
            ga_trackView(id)
        } catch (e) {
            console.error(e)
        }
    }, [chatDetails]);

    const trackChatCTA = useCallback((id:string) => {
        try {
            if(chatDetails?.chatName) id += '_' + chatDetails.chatName
            fl_trackCTA()
            ga_trackCTA(id)
            fb_trackCTA(id)
        } catch (e) {
            console.error(e)
        }
    }, [chatDetails]);

    const screenView = useCallback((screenName:string) => {
        try {
            setCurrentScreenName(screenName)
        } catch (e) {
            console.error(e)
        }
    }, []);

    useEffect(() => {
        try {
            ga_screenView({
                screen_name: currentScreenName,
                screen_title: currentScreenName,
                app_name: 'chatbot',
            })
            ga_pageView({
                page_location: window.location.href,
                page_title: currentScreenName,
            })
        } catch (e) {
            console.error(e)
        }
    }, [currentScreenName])

    /* events dependent on mount of context, i.e., page views related stuff */
    useEffect(() => {
        console.debug('Tracking: mounted')

        const pageDwell3Seconds = setTimeout(() => {
            console.debug('Tracking: page dwell of 3 seconds reached')
            try {
                fl_pageView()
                //ga_pageView() // this is auto-tracked by GA
                fb_pageView()
            } catch (e) {
                console.error(e)
            }
        }, 3000)

        const pageDwell20Seconds = setTimeout(() => {
            console.debug('Tracking: page dwell of 20 seconds reached')
            try {
                fl_trackDwell20Seconds()
                ga_trackDwell20Seconds()
                fb_trackDwell20Seconds()
            } catch (e) {
                console.error(e)
            }
        }, 20000)

        return () => {
            clearTimeout(pageDwell3Seconds)
            clearTimeout(pageDwell20Seconds)
            console.debug('Tracking: unmounted')
        }
    }, [])

    return <TrackingContext.Provider value={{trackSubmit, trackCTA, trackView, trackStartChat, trackEndChat, trackChatCTA, screenView}}>
        {children}
    </TrackingContext.Provider>
}

export {Tracking, TrackingContext};
