'use client'

type FBEventInformation = {
    event_function:string;
    event_name:string;
    event_options?:any;
}
const useFBTracking = (isOnline: boolean) => {
    
    const ONETRUST_CONSENT_CATEGORY = 'C0004'
    
    const sendFBEvent = ({event_function, event_name, event_options} : FBEventInformation) => {

        const consentGiven = window?.OnetrustActiveGroups?.includes(ONETRUST_CONSENT_CATEGORY) ?? false;
        console.debug('Tracking: trying to send, consentGiven', consentGiven)

        if(!consentGiven) return false

        if(window.fbq) window.fbq(event_function, event_name, event_options)

        return true;
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Abschicken - Buttonclick
    const fb_trackSubmit = (id:string) => {
        console.debug('Tracking: fb_trackSubmit')
        sendFBEvent({event_function: 'trackCustom', event_name: 'submit', event_options: {label: id}})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - CTA Buttonclick
    const fb_trackCTA = (id:string) => {
        console.debug('Tracking: fb_trackCTA')
        sendFBEvent({event_function: 'trackCustom', event_name: 'cta', event_options: {label: id}})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Dwell time 20 Sec
    const fb_trackDwell20Seconds = () => {
        console.debug('Tracking: fb_trackDwell20Seconds')
        sendFBEvent({event_function: 'trackCustom', event_name: 'dwell', event_options: {duration: '20-seconds'}})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Scrolldepth 50%
    const fb_trackScroll50Percent = () => {
        console.debug('Tracking: fb_trackScroll50Percent')
        sendFBEvent({event_function: 'trackCustom', event_name: 'scroll', event_options: {depth: '50-percent'}})
    }

    //Neue Leben - DE - Zukunfts-Ich LP - Pageview
    const fb_pageView = () => {
        console.debug('Tracking: fb_pageView')
        sendFBEvent({event_function: 'track', event_name: 'PageView'})
    }

    return {fb_trackSubmit, fb_trackCTA, fb_trackDwell20Seconds, fb_trackScroll50Percent, fb_pageView}
}

export default useFBTracking;