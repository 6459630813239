'use client'

import { useEffect, useRef, useState } from "react"

type FloodLightEventInformation = {
    src:string;
    type:string;
    cat:string;
}

const useFloodlightTracking = (isOnline: boolean) => {

    const ONETRUST_CONSENT_CATEGORY = 'C0004'

    const setTCFVariables = () => {
        if(window.__tcfapi) window.__tcfapi('getTCData', 2, function(tcData: any, success: boolean) {
            if(success) {
                console.debug(tcData); // This is the IAB consent data
                window.GDPR = tcData?.gdprApplies ? 1 : 0
                window.GDPR_CONSENT_755 = tcData?.tcString
            }
        });
    }
    
    const queue = useRef<FloodLightEventInformation[]>([]);
    useEffect(() => {
        setTCFVariables()
        window.addEventListener("OneTrustGroupsUpdated", event => { // try resending queued events on consent update
            console.debug('OneTrustGroupsUpdated')
            setTCFVariables()
            queue.current = queue.current.filter((info) => {
                return !sendFloodlightEvent(info) // keep elment in queue if it fails due to missing consent
            })
        })
    },[])

    const sendFloodlightEvent = ({src, type, cat} : FloodLightEventInformation) => {

        const consentGiven = window?.OnetrustActiveGroups?.includes(ONETRUST_CONSENT_CATEGORY) ?? false;
        console.debug('Tracking: trying to send, consentGiven', consentGiven)

        if(!consentGiven) return false

        const GDPR = window.GDPR // 0=GDPR does not apply 1=GDPR applies If not present, callee should do geoIP lookup, and GDPR applies for EU IP addresses
        const GDPR_CONSENT_755 = window.GDPR_CONSENT_755 // URL-safe base64-encoded GDPR consent string. Only meaningful if gdpr=1

        const a = Math.round(Math.random() * 10000000000000)
        const uri = `https://${src}.fls.doubleclick.net/activityi;src=${src};
        type=${type};
        cat=${cat};
        dc_lat=;
        dc_rdid=;
        tag_for_child_directed_treatment=;
        tfua=;
        npa=;
        gdpr=${GDPR};
        gdpr_consent=${GDPR_CONSENT_755};
        ord=${a}?
        `.replace(/\s/g, "")

        // reference https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?language=en_US
        if(window.OneTrust) window.OneTrust.InsertHtml(
            `<img width="1" height="1" style="display:none" src="${uri}" />`, 
            'floodlight-tags-container', // this container is auto-blocked by onetrust if category C0004 is not consented
            null, 
            null, 
            ONETRUST_CONSENT_CATEGORY)

        return true
    }
    
    // Neue Leben - DE - Zukunfts-Ich LP - Abschicken - Buttonclick
    const fl_trackSubmit = () => {
        console.debug('Tracking: fl_trackSubmit')
        const info = {src: '14831620', type: 'prof', cat: 'nlabschk'}
        sendFloodlightEvent(info)
    }

    // Neue Leben - DE - Zukunfts-Ich LP - CTA Buttonclick
    const fl_trackCTA = () => {
        console.debug('Tracking: fl_trackCTA')
        const info = {src: '14831620', type: 'prof', cat: 'nllpcta'}
        sendFloodlightEvent(info)
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Dwell time 20 Sec
    const fl_trackDwell20Seconds = () => {
        console.debug('Tracking: fl_trackDwell20Seconds')
        const info = {src: '14831620', type: 'prof', cat: 'nllp20s'}
        sendFloodlightEvent(info)
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Scrolldepth 50%
    const fl_trackScroll50Percent = () => {
        const info = {src: '14831620', type: 'eng', cat: 'nllp50'}
        sendFloodlightEvent(info)
    }

    //Neue Leben - DE - Zukunfts-Ich LP - Pageview
    const fl_pageView = () => {
        const info = {src: '14831620', type: 'prof', cat: 'nllppw'}
        if(!sendFloodlightEvent(info)) {
            queue.current.push(info)
        }
    }

    return {fl_trackSubmit, fl_trackCTA, fl_trackDwell20Seconds, fl_trackScroll50Percent, fl_pageView}
}

export default useFloodlightTracking;