'use client'

type GAEventInformation = {
    event_name:string;
    event_category:string;
    event_label:string;
}
type GA4EventInformation = {
    event_name:string;
    event_params:object;
}
type GA4ScreenViewParams = {
    screen_name:string;
    app_name?:string;
    screen_id?:string;
    screen_title?:string;
}
type GA4PageViewParams = {
    page_title:string;
    page_location:string;
}
type GA4SubmitParams = {
    filiale?:string;
}
const useGATracking = (isOnline: boolean) => {
    
    const ONETRUST_CONSENT_CATEGORY = 'C0002'
    
    const sendGAEvent = ({event_name, event_category, event_label} : GAEventInformation) => {

        const consentGiven = window?.OnetrustActiveGroups?.includes(ONETRUST_CONSENT_CATEGORY) ?? false;
        console.debug('Tracking: trying to send, consentGiven', consentGiven)

        if(!consentGiven) return false

        /*
        if(window.gtag) window.gtag('event', event_name, {
            'event_category': event_category,
            'event_label': event_label,
        })
        */

        if(window.gtag) window.gtag('event', event_name + '_' + event_category + '_' + event_label)

        return true;
    }

    const sendGAEventGA4 = ({event_name, event_params} : GA4EventInformation) => {

        const consentGiven = window?.OnetrustActiveGroups?.includes(ONETRUST_CONSENT_CATEGORY) ?? false;
        console.debug('Tracking: trying to send, consentGiven', consentGiven)

        if(!consentGiven) return false

        if(window.gtag) window.gtag('event', event_name, event_params)

        return true;
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Abschicken - Buttonclick
    const ga_trackSubmit = (id:string, event_params:GA4SubmitParams) => {
        console.debug('Tracking: ga_trackSubmit')
        sendGAEventGA4({event_name: 'button_click_submit_' + id, event_params})
        //sendGAEvent({event_name: 'button_click', event_category: 'submit', event_label: id})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - CTA Buttonclick
    const ga_trackCTA = (id:string) => {
        console.debug('Tracking: ga_trackCTA')
        sendGAEvent({event_name: 'button_click', event_category: 'cta', event_label: id})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Dwell time 20 Sec
    const ga_trackDwell20Seconds = () => {
        console.debug('Tracking: ga_trackDwell20Seconds')
        sendGAEvent({event_name: 'engagement', event_category: 'dwell', event_label: '20seconds'})
    }

    // Neue Leben - DE - Zukunfts-Ich LP - Scrolldepth 50%
    const ga_trackScroll50Percent = () => {
        console.debug('Tracking: ga_trackScroll50Percent')
        sendGAEvent({event_name: 'engagement', event_category: 'scroll', event_label: '50percent'})
    }

    //Neue Leben - DE - Zukunfts-Ich LP - Pageview
    const ga_pageView = (event_params: GA4PageViewParams) => {
        console.debug('Tracking: ga_pageView')
        sendGAEventGA4({event_name: 'page_view', event_params})
    }

    const ga_screenView = (event_params: GA4ScreenViewParams) => {
        console.debug('Tracking: ga_screenView', event_params)
        sendGAEventGA4({event_name: 'screen_view', event_params})
    }

    const ga_trackView = (id:string) => {
        console.debug('Tracking: ga_trackView')
        sendGAEvent({event_name: 'engagement', event_category: 'view', event_label: id})
    }

    return {ga_trackSubmit, ga_trackCTA, ga_trackDwell20Seconds, ga_trackScroll50Percent, ga_pageView, ga_trackView, ga_screenView}
}

export default useGATracking;