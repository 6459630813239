import { useEffect, useState } from 'react';
import UAParser from "ua-parser-js";

type Device = {
    isMobile: boolean,
    isTablet: boolean,
    isHandheld: boolean;
    isDesktop: boolean,
    isAndroid: boolean
}

export const useUserDevice = () => {
    const [device, setDevice] = useState<Device | null>(null);

    useEffect(() => {
        const parser = new UAParser();
        const parsedResult = parser.getResult();
        const parsedDevice = parsedResult.device;



        const isMobile = parsedDevice.type === "mobile";
        const isTablet = parsedDevice.type === "tablet";

        const isAndroid = parsedResult.os.name?.toLowerCase() === "Android".toLowerCase()

        const device: Device = {
            isMobile,
            isTablet,
            isHandheld: isMobile || isTablet,
            isDesktop: !isMobile && !isTablet,
            isAndroid
        }
        setDevice(device);
    }, []);

    return { device };
};

export default useUserDevice;