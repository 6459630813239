import { ResetTrigger } from "@/components/enums/UiState";
import clsx, { ClassValue } from "clsx";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { twMerge } from "tailwind-merge";

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function use4K() {
    const FK = useMediaQuery({ query: "(min-width: 2160px)" });
    return FK;
}

export function use4XL() {
    const FXL = useMediaQuery({ query: "(min-width: 1920px)" });
    return FXL;
}

export function use2XL() {
    const TXL = useMediaQuery({ query: "(min-width: 1536px)" });
    return TXL;
}

export function useXL() {
    const XL = useMediaQuery({ query: "(min-width: 1280px)" });
    return XL;
}

export function useLG() {
    const LG = useMediaQuery({ query: "(min-width: 1024px)" });
    return LG;
}

export function useMD() {
    const MD = useMediaQuery({ query: "(min-width: 768px)" });
    return MD;
}

export function useSM() {
    const SM = useMediaQuery({ query: "(min-width: 640px)" });
    return SM;
}

export function useXS() {
    const XS = useMediaQuery({ query: "(min-width: 500px)" });
    return XS;
}

export function use2XS() {
    const TXS = useMediaQuery({ query: "(min-width: 480px)" });
    return TXS;
}

export function use4XS() {
    const FXS = useMediaQuery({ query: "(min-width: 320px)" });
    return FXS;
}

let startTime: number | undefined;

export async function handleLogOnStart() {
    const name = "start";
    startTime = Date.now();
    const timestamp = new Date(startTime).toLocaleString("de-DE", { timeZone: "Europe/Berlin" });

    const body = {
        timestamp: timestamp,
    };
    try {
        await sendEventToBackend(name, body);
        console.log("Daten erfolgreich gesendet");
    } catch (error) {
        console.error("Fehler beim Senden der Daten: ", error);
    }
}

export async function handleLogOnReset(phase: string, lastChat: any, trigger: string) {
    const name = "reset";
    const endTime = Date.now();
    let durationInSeconds = startTime ? (endTime - startTime) / 1000 : 0;

    switch (trigger) {
        case ResetTrigger.CHATINACTIVETIMER:
            durationInSeconds -= 80;
            break;
        case ResetTrigger.ENDOFCALLTIMER:
            durationInSeconds -= 60;
            break;
        case ResetTrigger.GENERALINACTIVETIMER:
            durationInSeconds -= 120;
            break;
        default:
            break;
    }
    const body = {
        phase: phase,
        lastChat: lastChat.buttons[0].label.data.attributes.key,
        timestamp: durationInSeconds,
        trigger: trigger,
    };

    console.log("Logging Event " + body);

    try {
        await sendEventToBackend(name, body);
        console.log("Daten erfolgreich gesendet");
    } catch (error) {
        console.error("Fehler beim Senden der Daten: ", error);
    }
}

async function sendEventToBackend(eventName: string, body: Record<string, any>): Promise<void> {
    console.log("send data to backend " + eventName + JSON.stringify(body, null, 2));
    const url = "http://127.0.0.1:5000/events";

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ eventName, ...body }),
        });

        if (!response.ok) {
            console.error("Failed to send event to backend", response.status, response.statusText);
        }
    } catch (error) {
        console.error("Error occurred while sending event to backend", error);
    }
}

export default sendEventToBackend;
